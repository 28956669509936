import { lazy, Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import Header from "./components/Header";
import LoadingSplash from "./components/LoadingSplash";
import "./style.scss";
import "./assets/fonts/fonts.css";
import { Box } from "@mui/material";

AOS.init();

const Home = lazy(() => import("./views/home"));


function App() {

  return (
    <Suspense fallback={<LoadingSplash />}>
      <Box display="flex" flexDirection="column" className={`app-container`}>
        <Header />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Box>
    </Suspense>
  );
}

export default App;

import { Box, Link, useMediaQuery, Drawer, SvgIcon, styled } from "@mui/material";

import "./header.scss";
import Close from "../../assets/images/close.svg";
import Menu from "../../assets/images/menu.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as More } from "../../assets/images/more.svg";
import { ReactComponent as Twitter } from "../../assets/images/twitter.svg";
import { ReactComponent as Doc } from "../../assets/images/doc.svg";
import { NavLink } from "react-router-dom";
import { useState } from "react";

export default function Header() {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const [isOpen, setIsOpen] = useState(false);
  const DrawerBox = styled(Drawer)`
    .MuiDrawer-paper {
      background: #26080d;
      width: 80vw;
      padding: 24px 16px;
    }
  `;

  return (
    <>
      <div className="header">
        <Box display="flex" alignItems="center" justifyContent="space-between" className="header-view">
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Link component={NavLink} to="/" className="logo">
              <Logo />
            </Link>
            {!isSmallScreen && (
              <Box display="flex" alignItems="center" className="navList font-14 font-weight-b">
                <div className="navItem">
                  <a href="" target={"_blank"} className="color1">
                    Trade
                  </a>
                </div>
                <div className="navItem">
                  <a href="" target={"_blank"} className="color1">
                    Earn
                  </a>
                </div>

                <div className="navItem">
                  <a href="" target={"_blank"} className="color1">
                    Prep
                  </a>
                </div>
                <div className="navItem">
                  <a href="" target={"_blank"} className="color1">
                    <More />
                  </a>
                </div>
              </Box>
            )}
          </Box>
          {isSmallScreen ? (
            <>
              <img src={Menu} onClick={() => setIsOpen(true)} />
            </>
          ) : (
            <Box display="flex" alignItems="center" style={{ gridGap: isSmallScreen ? "16px" : "32px" }}>
              <a href="https://twitter.com/CAKEX_opbnb" target={"_blank"} className="">
                <Twitter />
              </a>
              <a href="https://docs.pancakex.finance/" target={"_blank"} className="">
                <Doc />
              </a>
            </Box>
          )}
        </Box>
      </div>

      <DrawerBox anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Link component={NavLink} to="/" className="logo">
            <Logo />
          </Link>
          <img src={Close} onClick={() => setIsOpen(false)} />
        </Box>
        <Box display="flex" flexDirection="column" className="flex-1">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            mt="32px"
            style={{ gridGap: isSmallScreen ? "16px" : "32px" }}
            className="navList font-14 font-weight-b"
          >
            <div className="navItem">
              <a href="" target={"_blank"} className="color1">
                Trade
              </a>
            </div>
            <div className="navItem">
              <a href="" target={"_blank"} className="color1">
                Earn
              </a>
            </div>

            <div className="navItem">
              <a href="" target={"_blank"} className="color1">
                Prep
              </a>
            </div>
            <div className="navItem">
              <a href="" target={"_blank"} className="color1">
                <More />
              </a>
            </div>
          </Box>
          <Box
            mt="48px"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            style={{ gridGap: isSmallScreen ? "16px" : "32px" }}
          >
            <a href="https://twitter.com/CAKEX_opbnb" target={"_blank"} className="">
              <Twitter />
            </a>
            <a href="https://docs.pancakex.finance/" target={"_blank"} className="">
              <Doc />
            </a>
          </Box>
        </Box>
      </DrawerBox>
    </>
  );
}
